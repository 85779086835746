// Color palette
$custom-grey: #8A8E82;
$brand-primary: $custom-grey;

// Typography
@import url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700,700italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,300,300italic,400italic,700,700italic,900,900italic);


// Navbar
$navbar-default-bg: #04171D;
$navbar-default-color: #FFFFFF;
$navbar-default-link-color: $custom-grey;
$navbar-default-link-active-color: #FFFFFF;
$navbar-default-link-hover-color: #FFFFFF;
$navbar-default-link-active-bg: #04171D;
$navbar-height: 80px;
$navbar-collapse-max-height: auto; // fix overflow issue in mobile navbar

// Typography
$font-family-sans-serif: "Merriweather", serif;
$headings-font-family: "Lato", sans-serif;
$headings-font-weight: 500;
$font-family-base: $font-family-sans-serif;

$text-color: #04171D;
$font-size-base: 16px;


// Container Sizing
$grid-gutter-width: 30px;
$container-tablet: (680px + $grid-gutter-width);
$container-desktop: (720px + $grid-gutter-width);
$container-large-desktop: (840px + $grid-gutter-width);