/*** mobile ***/
.navbar-toggle {
  border: none;
  padding: 4px;
  width: 40px;
  .bars {
    width: 80%;
    height: 2px;
    margin: 4px;
    background-color: #8A8E82;
  }
}
.navbar-toggle:hover {
  background-color: #04171D;
  .bars {
    background-color: #04171D;
  }  
}

/*** logo ***/
.navbar-brand {
  padding: 0;
  height: 84px;
  img {
    height: 90%;
    margin-top: 5px;
    display: inline-block;
    vertical-align: top;
  }
  #title {
    display: inline-block;
    vertical-align: top;
    margin-top: 12px;
    h1 {
      font-size: 24px;
      margin: 4px;
    }
    h2 {
      font-size: 16px;
      font-style: italic;
      margin: 4px;
      font-family: "Merriweather", serif;
    }
  }
}

/*** links ***/
.navbar-nav {
  border: none;
  font-size: 20px; 
  li > a {
    font-family: "Lato", serif;
    padding: 16px 0px;
  }
  li > a:hover {
    text-shadow: 1px 1px 1px #3B473D;
  }
  .dropdown {
    /*** caret ***/
    .caret-down {
      border-top: 4px solid #5C8A8A;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      content: "";
      display: inline-block;
      height: 0;
      width: 0;
      vertical-align: middle; 
    }
    .dropdown-menu {
      -webkit-transition: height 0.5s ease-out;
      -moz-transition: height 0.5s ease-out;
      transition: height 0.5s ease-out;
      li {
        margin: 0px;
        padding: 4px;
        font-size: 16px;
        a {
          padding: 3px 0;
          margin:0px;
          color: #5C8A8A;
        }
        a:hover {
          text-shadow: none;
        }
      }
    }
  }


  // Media queries 
  @media (max-width: $screen-sm-min - 1px) {
    li {
      padding: 0px
    }
    .dropdown:hover, .dropdown:active {
      /*** caret ***/
      .caret-down {
        border-top: 4px solid #FFFFFF;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        content: "";
        display: inline-block;
        height: 0;
        width: 0;
        vertical-align: middle; 
      }
    } 
  }
  @media (min-width: $screen-sm-min) {
    .dropdown:hover, .dropdown:active {
       > a {
        color: white;
      }
      /* show dropdown on hover */
      .dropdown-menu {
        display: block;
      }
      /*** caret ***/
      .caret-down {
        border-top: 4px solid #FFFFFF;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        content: "";
        display: inline-block;
        height: 0;
        width: 0;
        vertical-align: middle; 
      }
    } 
  }  
}



