/* DEFAULT IMAGE BACKGROUND SETTINGS */
.img-bg {
	background: #04171D;
	/* rounded corners */
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-top-left-radius: 4px;
	/* drop shadow */
	-moz-box-shadow: 2px 2px 2px #3B473D;
	-webkit-box-shadow: 2px 2px 2px #3B473D;
	box-shadow: 2px 2px 2px #3B473D;
}

/* HOME IMAGE BACKGROUND */
.home-img-bg {
	max-width: 734px;
	width: 80%;
	height: auto;
	margin: 4px auto;
	@extend .img-bg;
	img {
		max-width: 720px;
		width: calc(100% - 14px);
		height: auto;
		margin: 7px;
		padding: 0px;
	}
}

/* ABOUT IMAGE BACKGROUND */
.about-img-bg {
	height:214px;
	width:214px;
	margin: 4px auto;
	@extend .img-bg;
	img {
		margin: 7px;
		width: 200px;
		height: 200px;
		padding: 0px;
	}
}

/* GALLERY SETTINGS */
/* default gallery background settings */
.gallery-img-bg {
	@extend .img-bg;
	margin: 10px auto 10px auto;
	.thumb {
		display: inline-block;
		width: 140px;
		height: 140px;
		overflow: hidden;
		margin: 7px 7px 7px auto;
		img {
			width:140px;
		}
		img:hover {
			width:106%;
			height: auto;
		  -webkit-transition: all 0.5s ease-out;
		  transition: all 0.5s ease-out;
		}
	}
	.first {
		margin-left: 7px;
	}
	.last {
		margin-left: 0px;
	}
}
/* column presets */
.gallery-img-bg-1 {
	@extend .gallery-img-bg;
	$i : 1;
	$j : 1;
	width: ($i * 140px) + ($i * 7px) + 7px;
	height: ($j * 140px) + ($j * 7px) + 7px;
}
.gallery-img-bg-2 {
	@extend .gallery-img-bg;
	$i : 2;
	$j : 1;
	width: ($i * 140px) + ($i * 7px) + 7px;
	height: ($j * 140px) + ($j * 7px) + 7px;
}
.gallery-img-bg-4 {
	@extend .gallery-img-bg;
	$i : 4;
	$j : 1;
	width: ($i * 140px) + ($i * 7px) + 7px;
	height: ($j * 140px) + ($j * 7px) + 7px;
	@media (max-width: $screen-sm-min) {
		width: (($i * 0.5) * 140px) + (($i * 0.5) * 7px) + 7px;
		height: (($j * 2) * 140px) + (($j * 2) * 7px) + 7px;
		.thumb:nth-child(1) {
			margin: 7px 7px 2px 7px;
		}
		.thumb:nth-child(2) {
			margin: 7px 7px 2px 0px;
		}
		.thumb:nth-child(3) {
			margin: 0px 7px 7px 7px;
		}
		.thumb:nth-child(4) {
			margin: 0px 7px 7px 0px;
		}
	}
}
