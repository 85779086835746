/* CONTACT FORM STYLE */
form {
	min-height: 154px;
	width: 80%;
	min-width: 320px;
	margin: 24px auto;
	background: #04171D;
	padding: 10px;
	/* rounded corners */
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-top-left-radius: 4px;
	/* drop shadow */
	-moz-box-shadow: 2px 2px 2px #3B473D;
	-webkit-box-shadow: 2px 2px 2px #3B473D;
	box-shadow: 2px 2px 2px #3B473D;
	label {
		color: white;
		font-weight: normal;
		width: 100%;
		display: block;
		.form-label {
			width: 120px;
			display: inline-block;
			vertical-align: top;
		}
		input, textarea {
			color: #04171D;
			width: calc(100% - 120px);
			resize: vertical;
		}
	}
	#submit {
		// float: right;
		margin-left: calc(100% - 54px);
	}
}

.professional-links {
	margin: 10px;
	a {
		text-decoration: none;
		margin: 2px;
	}
	a:first-child {
		margin-left: 18px;
	}
	img:hover {
    opacity: 0.75;
    filter: alpha(opacity=75); /* For IE8 and earlier */
	}
}